<template>
  <div>
    <vx-card class="mb-4">
      <!-- <vs-row vs-type="flex" vs-justify="center">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
          v-if="contactType == database.contactType.Ita"
        >
          <vs-button
            radius
            color="danger"
            type="gradient"
            icon-pack="material-icons"
            icon="touch_app"
            size="large"
            @click="goNeo"
          ></vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-button
            radius
            color="rgb(26, 186, 138)"
            icon-pack="material-icons"
            icon="calendar_today"
            size="large"
            @click="$router.push({ name: 'purchases-monthlyPayment' })"
          ></vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-button
            radius
            color="warning"
            icon-pack="material-icons"
            icon="arrow_upward"
            size="large"
            @click="$router.push({ name: 'purchases-upgrade' })"
          ></vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-button
            radius
            color="rgb(218, 45, 8 )"
            icon-pack="feather"
            icon="icon-shopping-bag"
            size="large"
            @click="$router.push({ name: 'purchases-products' })"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="center" class="mb-base">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
          v-if="contactType == database.contactType.Ita"
        >
          <p class>Neo</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <p>
            {{
            $t(resources.MonthlyPayment.i18n) ||
            $t(resources.MonthlyPayment.name)
            }}
          </p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <p>{{ $t(resources.Upgrade.i18n) || $t(resources.Upgrade.name) }}</p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <p>{{ $t(resources.OtherProducts.i18n) || $t(resources.OtherProducts.name) }}</p>
        </vs-col>
      </vs-row> -->
      <h4 class="mb-4">{{$t(resources.Filters.i18n) || resources.Filters.name}}</h4>
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/5 mb-2">
          <label>
            {{
            $t(resources.StartDate.i18n) || resources.StartDate.name
            }}
          </label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="start_date"></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/5 mb-2">
          <label>{{ $t(resources.EndDate.i18n) || resources.EndDate.name }}</label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="end_date"></datepicker>
        </div>
        <vs-button
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-refresh-ccw"
          class="mt-5"
          @click="cleanDate()"
        ></vs-button>
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{$t(resources.Type.i18n) || resources.Type.name}}</label>
            <v-select
              v-model="type"
              :clearable="true"
              :options="typeOption"
              :reduce="item=> item.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/5 mb-2">
          <label class="vs-input--label">{{$t(resources.Stage.i18n) || resources.Stage.name}}</label>
          <v-select
            v-model="stageId"
            :clearable="false"
            :options="stageList"
            :reduce="x=> x.id"
            label="name"
            scrollable
          />
        </div>

        <div class="vx-col mb-2 mt-5">
          <vs-button
            color="primary"
            type="filled"
            @click="get()"
          >{{ $t(resources.Search.i18n) || resources.Search.name }}</vs-button>
        </div>
      </div>
    </vx-card>
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span
                  class="mr-2"
                >{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ purchaseList.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : purchaseList.length }} of {{ purchaseList.length }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
              CVS
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="purchaseList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-selected="onRowSelected"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import moment from "moment";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    AgGridVue,
    Datepicker,
    vSelect,
  },
  data() {
    return {
      database: database,
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      contactType: JSON.parse(localStorage.getItem("userInfo")).contactType,
      start_date: new Date(),
      end_date: new Date(),
      type: "",
      typeOption: [],
      paid: "",
      stageId: 23,
      stageList: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      purchaseList: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Type"),
          field: "purchaseTypeId",
          hide: true,
        },
        {
          headerName: "#",
          field: "purchaseId",
          filter: true,
          width: 105,
        },
        {
          headerName: this.$i18n.t("Total"),
          field: "total",
          filter: true,
          width: 150,
        },
        {
          headerName: this.$i18n.t("Payments"),
          field: "totalPayments",
          filter: true,
          width: 150,
        },
        {
          headerName: this.$i18n.t("PaymentsConfirmed"),
          field: "paymentsConfirmed",
          filter: true,
          width: 150,
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "stageName",
          filter: true,
          width: 130,
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "purchaseTypeName",
          filter: true,
        },
        {
          headerName: this.$i18n.t("OrderNumber"),
          field: "orderNumber",
          filter: true,
        },
        {
          headerName: this.$i18n.t("CreatedBy"),
          field: "createdBy",
          filter: true,
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true,
        },
      ],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  created: function () {
    this.getPurchaseType();
    this.getStage();
    this.get();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    cleanDate() {
      this.start_date = null;
      this.end_date = null;
    },

    async getPurchaseType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}PurchaseType/GetSelect/${this.$i18n.locale}`,
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.typeOption = [];
          } else {
            this.typeOption = result.data.filter((x) => x.id != 1);
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async get() {
      let startDate = "";
      let endDate = "";

      if (this.start_date != null && this.end_date != null) {
        startDate = moment(this.start_date).format("YYYY-MM-DD");
        endDate = moment(this.end_date).format("YYYY-MM-DD");
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}purchase/get`,
        data: {
          status: this.stageId,
          itaCode: this.itaCode,
          type: this.type,
          start_date: startDate,
          end_date: endDate,
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale,
        },
      }).then(
        (result) => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.purchaseList = [];
          } else {
            this.purchaseList = result.data.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    goNeo() {
      if (localStorage.getItem("haveNeo") != 0) {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: this.$i18n.t("MsgHaveNeo"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle",
        });
      } else {
        this.$router.push({
          name: "purchases-neo",
        });
      }
    },

    onRowSelected(event) {
      if (
        event.node.data.purchaseTypeId == this.database.purchaseType.membership
      ) {
        this.$router.push({
          name: "purchases-membership",
          params: {
            purchaseId: event.node.data.purchaseId,
          },
        });
      } else if (
        event.node.data.purchaseTypeId ==
          this.database.purchaseType.monthlyPayment ||
        event.node.data.purchaseTypeId ==
          this.database.purchaseType.automaticMonthlyPayment
      ) {
        this.$router.push({
          name: "purchases-monthlyPayment",
          params: {
            purchaseId: event.node.data.purchaseId,
          },
        });
      } else if (
        event.node.data.purchaseTypeId == this.database.purchaseType.upgrade
      ) {
        this.$router.push({
          name: "purchases-upgrade",
          params: {
            purchaseId: event.node.data.purchaseId,
          },
        });
      } else if (
        event.node.data.purchaseTypeId == this.database.purchaseType.another
      ) {
        this.$router.push({
          name: "purchases-products",
          params: {
            purchaseId: event.node.data.purchaseId,
          },
        });
      }
    },

    async getStage() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}Stage/${this.$i18n.locale}/4`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.stageList = [
              {
                id: "",
                name: this.$i18n.t("All"),
              },
            ];
          } else {
            result.data.push({
              id: "",
              name: this.$i18n.t("All"),
            });
            this.stageList = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>
<style>
.includeIconOnly.large {
  width: 60px !important;
  height: 60px !important;
  font-size: 0.7em;
}

.vs-button.large {
  font-size: 3.25rem;
}

.vs-button.large .vs-button--icon {
  font-size: 3.25rem;
}
</style>
